import React from "react"

import { Container } from 'reactstrap' 
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-locations' 
import worldIcon from '../../assets/images/world.png' 
import redLocation from '../../assets/images/red-location.png' 
import truckAbsolute from '../../assets/images/truck-location.png' 
 
 
export default () => (
  <LayoutEsp>
    <SEO title="Ubicaciones" />
    <Slider title="UBICACIONES"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div>  
	<div className="blue-map-background-page" >
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 locations-block locations-set">
				<img className="redValue" src={redLocation} alt="red icon location"/> 
				<h4 className="locationH">{'UBICACIONES'}</h4> 
				<p className="locationP">Estamos donde</p> 
				<p className="locationP"><b>tu nos necesitas.</b></p>  
				<img className="truckAbsolute9" src={truckAbsolute} alt="truck"/> 
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 locations-block">
				<div className="row locations-background">
					<h4 className="whiteHLocation">OFICINA <b>CORPORATIVA</b></h4> 
					<hr className="whiteLine"/>
					<p className="locationP2"><b>350 Nina Lee RD</b></p>
					<p className="locationP2">Calexico, CA</p>
					<p className="locationP2">C.P. 92231</p>
					<p className="locationP2">Tel: 760 3570 991</p>
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block-page">
				<div className="row locations-backgroundCity"> 
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'CALEXICO'}</h4> 
					<hr className="thinLine"/> 
					<p className="locationPCity"><b>A.G. Warehouse, 350 Nina Lee RD.</b></p>
					<p className="locationPCity">Calexico,&nbsp;&nbsp;&nbsp;&nbsp;CA 92231</p>   
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block-page">
				<div className="row locations-backgroundCity"> 
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'MEXICALI'}</h4> 
					<hr className="thinLine"/> 
					<p className="locationPCity"><b>Km 11.5 Carretera Aeropuerto,<br/> Col. Mariano Abasolo</b></p>
					<p className="locationPCity">Mexicali, B.C. &nbsp;&nbsp;&nbsp;&nbsp;21600</p>  
					
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block-page">
				<div className="row locations-backgroundCity">
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'OTAY'}</h4> 
					<hr className="thinLine"/> 
					<p className="locationPCity"><b>Eje Poniente #195<br/> Col. Magisterial</b></p>
					<p className="locationPCity">Tijuana,B.C. &nbsp;&nbsp;&nbsp;&nbsp;22470</p>  
				
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block-page">
				<div className="row locations-backgroundCity">
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'LOS ANGELES'}</h4>
					<hr className="thinLine"/>  
					<p className="locationPCity"><b>280 E Del Amo</b></p>
					<p className="locationPCity">Compton,CA &nbsp;&nbsp;&nbsp;&nbsp;90221</p>  
				
				</div>
			  </div>
			</div>
		</Container>
	</div>   
  </LayoutEsp>
)
