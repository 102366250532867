import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler, 
  Nav, 
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, 
} from 'reactstrap';
import Link from './link' 
import logo from '../assets/images/logo-head.png' 
import { FaPhone, FaEnvelope } from 'react-icons/fa'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="light" light expand="md"> 
      <Container>
		<ul className="navbar-nav bd-navbar-nav flex-row navbar-right top-black md-none">
			<li  className='nav-item nav-right'>
			  <Link to="http://yard.agwarehouse-chb.com:8080/" className="btn btn-blue">Servicio de rastreo</Link>
			</li>
			<li  className='nav-item nav-right'>
			  <Link to="http://yard.gutierreztrucking.com:8080/" className="btn btn-blue">Inventario de patio</Link>
			</li>
			<li  className='nav-item nav-right'>
			  <Link to="https://aceweb.rbsystems.com/AGWHSEWEB/Login.aspx" className="btn btn-blue">Inventario de Almacen</Link>
			</li> 
			<li  className='nav-item nav-right'>
			  <Link to="/ESP/cotiza" className="btn btn-blue">Cotiza</Link>
			</li> 
		</ul>
		<ul className="navbar-nav bd-navbar-nav flex-row navbar-right  md-none">
			<li  className='nav-item nav-right' >
			  <Link to="/ESP/carrera" className="btn btn-red"> Conductores </Link>
			</li>
			<li  className='nav-item nav-right' >
			  <Link to="/ESP/carrera" className="link-blue active"> Únetenos </Link>
			</li>  
			<li  className='nav-item nav-right line-white' >
			   
			</li>  
			<li  className='nav-item nav-right' >
			  <a href="tel:18882561210" className="link-blue active border-button"><FaPhone className="rotate mr-1" />1 (888) 256 - 1210</a>
			</li>
			<li  className='nav-item nav-right' >
			  <a href="mailto:info@gutierreztruckin.com" className="link-blue active"><FaEnvelope  className=" mr-1" />info@gutierreztrucking.com</a>
			</li> 
		</ul>
        <Link to="/ESP" className="navbar-brand">
          <img src={logo} className="logo" alt="Logo"/>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
			<Nav className="ml-auto" navbar>
					<li className="nav-item">
						<a className="nav-link" href="/ESP/nosotros">NOSOTROS</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/ESP/servicios">SERVICIOS</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/ESP/equipo">EQUIPO</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/ESP/ubicaciones">UBICACIONES</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/ESP/tecnologia">TECNOLOGIA</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/ESP/carrera">CARRERA</a>
					</li>
					<li className="nav-item">
						<a aria-current="page" className="nav-link active" href="/ESP/contacto">CONTACTO</a>
					</li>
					<UncontrolledDropdown nav inNavbar>
					  <DropdownToggle nav caret>
						
					  </DropdownToggle>
					  <DropdownMenu right>
						<DropdownItem href="/ESP/como">
						¿ COMO CONDUCIMOS ?
						</DropdownItem>
						<DropdownItem href="/ESP/quejas">
						  QUEJAS
						</DropdownItem> 
						<DropdownItem href="/ESP/aplicacion">
						  APLICACION DE CREDITO
						</DropdownItem>
					  </DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
			<Link to="/ESP/" className="navbar-brand lenguage esp">
			  ESP
			</Link>
			<Link to="/" className="navbar-brand lenguage eng">
			  ENG
			</Link>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
