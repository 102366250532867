import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {  FaPhone, FaEnvelope } from 'react-icons/fa';
import Link from './link';
import styled from "styled-components"
import logoFooter from '../assets/images/footer-logo.png'

const Footer = styled.footer`
  position: relative;
  padding: 2rem 0;
  padding-bottom: 4rem;
  background: #143b7f;
  z-index: 5;
  a, a:hover {
    color: inherit;
  }
  ul {
    color: rgba(0, 0, 0, 0.5);
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      a, a:hover {
        color: inherit;
      }
    }
  }
`
 
let FooterLink = ({to, children}) => (
  <li className="link-footer">
    <Link   to={to}>
      {children}
    </Link>
  </li>
)

export default () => (
  <Footer>
    <Container>
      <Row>
        <Col> 
		  <Row>
		    <Col>
			  <ul   className="logoFooter">
				<FooterLink  to="/">
					<img src={logoFooter} alt="Logo Footer"/>
				</FooterLink>
			  </ul> 
		    </Col> 
		    <Col  className="md-none">
			  <ul>
				<FooterLink to="/ESP/nosotros">NOSOTROS</FooterLink>
				<FooterLink to="/ESP/servicios">SERVICIOS</FooterLink>
				<FooterLink to="/ESP/equipo">EQUIPO</FooterLink>
				<FooterLink to="/ESP/ubicaciones">UBICACIONES</FooterLink>
			  </ul>
		    </Col> 
		 </Row> 
		</Col> 
        <Col xs="6" sm="6" md="3" className="md-none">
		  <Row>
			  <ul>
				<FooterLink to="/ESP/tecnologia">TECNOLOGIA</FooterLink>
				<FooterLink to="/ESP/carrera">CARRERA</FooterLink>
				<FooterLink to="/ESP/contacto">CONTACTO</FooterLink>
			  </ul>
          </Row>
        </Col>
        <Col xs="12" sm="12" md="3" className="contactFooter">
		  <Row>
			<Col>
			  <h5 className="h-footer"><b>CONTACTO</b></h5>  
			  <ul>
				<li  className='nav-item nav-left lifooter' >
				  <a href="mailto:info@gutierreztrucking.com" className="link-blueFooter active"><FaEnvelope /> &nbsp;&nbsp;info@gutierreztrucking.com</a>
				</li> 
				<li  className='nav-item nav-left lifooter' >
				  <a href="tel:18882561210" className="link-blueFooter active border-button"><FaPhone className="rotate" />&nbsp;&nbsp; 1 (888) 256 - 1210 </a>
				</li>
			  </ul>
			 </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <div className="footerLabel">
		<Container>
		  <div className="row footerLabelRow">
		    <Col>
				<p className="creditos">
					2019 Gutierrez Trucking, LLC.
				</p>
		    </Col>
		  </div>
		</Container>
    </div >
  </Footer>
)
